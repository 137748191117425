import { Input } from "~/components/ui/forms/inputs";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/forms/select";

import { Text } from "~/components/ui/text";

import type { FieldMetadata } from "@conform-to/react";

import countriesList from "~/utils/const/countries-list.json";
import { cn } from "~/utils";
import React from "react";
import { formatUSPhoneNumber } from "~/utils/formatUSPhoneNumber";

export function UserInfoStep({
  fields,
  errors,
  country,
  phoneNumber,
}: {
  fields: Record<string, FieldMetadata>;
  errors: Record<string, string[]>;
  country: {
    value: string | undefined;
    change: React.Dispatch<React.SetStateAction<string | undefined>>;
    focus: () => void;
    blur: () => void;
  };
  phoneNumber: {
    value: string | undefined;
    change: React.Dispatch<React.SetStateAction<string | undefined>>;
    focus: () => void;
    blur: () => void;
  };
}) {
  const _country =
    fields?.country?.value &&
    JSON.parse((fields?.country?.value as string) || (country.value as string));

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-8">
        <Input
          id={fields.firstName.name}
          name={fields.firstName.name}
          defaultValue={fields.firstName.initialValue as string | undefined}
          placeholder="Johnny"
          wrapperClass="w-1/2"
          type="text"
          aria-invalid={
            fields.firstName.errors
              ? fields.firstName.errors.length > 0
              : undefined
          }
          label="First Name *"
          autoComplete="given-name"
          error={
            fields.firstName.errors
              ? fields.firstName.errors.join(", ")
              : undefined
          }
        />
        <Input
          id={fields.lastName.name}
          name={fields.lastName.name}
          defaultValue={fields.lastName.initialValue as string | undefined}
          type="text"
          wrapperClass="w-1/2"
          aria-invalid={
            fields.lastName.errors
              ? fields.lastName.errors.length > 0
              : undefined
          }
          label="Last Name *"
          autoComplete="family-name"
          error={
            fields.lastName.errors
              ? fields.lastName.errors.join(", ")
              : undefined
          }
        />
      </div>

      <Input
        type="tel"
        id={fields.phoneNumber.name}
        name={fields.phoneNumber.name}
        label="Phone number"
        aria-invalid={
          fields.phoneNumber.errors
            ? fields.phoneNumber.errors.length > 0
            : undefined
        }
        error={[
          ...(fields.phoneNumber.errors || []),
          ...(errors.phoneNumber || []),
        ]
          .filter(Boolean)
          .join(", ")}
        value={phoneNumber.value}
        onChange={(e) =>
          phoneNumber.change(formatUSPhoneNumber(e.target.value, _country.code))
        }
      />
      <div>
        <Text color="brand" variant="small" fontWeights="bold">
          Country
        </Text>
        <Select
          name={fields.country.name}
          value={
            country.value ||
            JSON.stringify({
              name: "United States",
              code: "US",
              phone_code: "+1",
            })
          }
          onValueChange={(value) => country.change(value)}
        >
          <SelectTrigger
            dir="ltr"
            className={cn(
              "w-full rounded-md border-gray-500 text-brand shadow-sm sm:text-sm",
              "bg-white focus:border focus:border-brand focus:ring-brand focus:ring-offset-0 focus-visible:border-brand focus-visible:ring-accent focus-visible:ring-offset-0",
            )}
          >
            <SelectValue placeholder="United States" />
          </SelectTrigger>
          <SelectContent className="max-h-[40vh]">
            {countriesList.map((country) => (
              <SelectItem key={country.code} value={JSON.stringify(country)}>
                {country.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}
