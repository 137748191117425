import { Input } from "~/components/ui/forms/inputs";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/forms/select";

import countriesList from "~/utils/const/countries-list.json";
import { cn } from "~/utils";
import type { GoogleAuthProps } from "../googleAuth/entity";

import { formatUSPhoneNumber } from "~/utils/formatUSPhoneNumber";
import { safeJsonParse } from "~/utils/safeParseJson";

type GoogleAuthUserInfoStepProps = GoogleAuthProps & {
  errors: any;
};
export function GoogleAuthUserInfoStep({
  userData,
  setUserData,
  errors,
}: GoogleAuthUserInfoStepProps) {
  const country = userData?.country
    ? safeJsonParse<{ code: string; phone_code: string }>(userData?.country)
    : { code: "US", phone_code: "+1" };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-8"></div>

      <Input
        type="tel"
        label="Phone number"
        value={userData?.phoneNumber}
        onChange={(e) => {
          setUserData((prev) => ({
            ...prev,
            phoneNumber: formatUSPhoneNumber(e.target.value, country?.code),
          }));
        }}
        error={errors?.phoneNumber}
      />
      <div>
        <p className="text-left text-sm font-bold text-brand">Country</p>
        <Select
          value={
            userData?.country ||
            JSON.stringify({
              name: "United States",
              code: "US",
              phone_code: "+1",
            })
          }
          onValueChange={(value) => {
            setUserData((prev) => ({ ...prev, country: value }));
          }}
        >
          <SelectTrigger
            dir="ltr"
            className={cn(
              "w-full rounded-md border-gray-500 text-brand shadow-sm sm:text-sm",
              "bg-white focus:border focus:border-brand focus:ring-brand focus:ring-offset-0 focus-visible:border-brand focus-visible:ring-accent focus-visible:ring-offset-0",
            )}
          >
            <SelectValue placeholder="United States" />
          </SelectTrigger>
          <SelectContent className="max-h-[40vh]">
            {countriesList.map((country) => (
              <SelectItem key={country.code} value={JSON.stringify(country)}>
                {country.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}
